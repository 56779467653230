import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Shift KPI", "has-active": false, "search-by": "ShiftName", "ph-search-by": "Search by Shift" }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active mx-1", attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1)];
  } }]) }, [_c("text-field", { key: "KpiDate", attrs: { "data-index": "kpiDate", "title": "Date", "parse": _vm.parseDate, "sorter": true } }), _c("text-field", { key: "ShiftName", attrs: { "data-index": "shiftName", "width": 362, "title": "Shift" } }), _c("text-field", { key: "ShiftLengthHours", attrs: { "data-index": "shiftLengthHours", "title": "Shift Hour", "sorter": true } }), _c("text-field", { key: "TotalNumberofPeople", attrs: { "data-index": "totalNumberofPeople", "title": "People", "sorter": true } }), _c("text-field", { key: "NumberofCrews", attrs: { "data-index": "numberofCrews", "title": "Crews", "sorter": true } }), _c("text-field", { key: "NumberPeopleAbsent", attrs: { "data-index": "numberPeopleAbsent", "title": "Absent", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListShiftKPI",
  inject: ["crud", "area"],
  created() {
    this.crud.setFilter("AreaId", {
      operator: "Eq",
      value: this.area.value ? this.area.value : -1
    });
    this.fetchList(true);
  },
  methods: {
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY");
    },
    fetchList(isCreated) {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      if (!isCreated)
        this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListShiftKpi = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-shift-kpi" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "line-performance.kpidata", "api-url": _vm.apiUrl, "create-route": "/line-performance/shift-kpi/create", "edit-route": "/line-performance/shift-kpi/:id" } }, [_c("list-shift-kpi")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListShiftKpi
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "report",
          title: "Report",
          path: ""
        },
        {
          key: "shift-kpi",
          title: "Shift KPI",
          path: "/line-performance/shift-kpi"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
